if (!document.getElementById('gifty-sandbox')) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.id = 'gifty-sandbox';
    script.async = true;
    script.type = 'module'
    script.src = import.meta.env.PROD ? `${import.meta.env.BASE_URL}/js/main.js` :  new URL('./main.js', import.meta.url).href;

    document.head.appendChild(script);
}
